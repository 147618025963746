/* DoubleRange.module.css */
.range {
    pointer-events: none;
    position: absolute;

    outline: none;
}

.rangeLeft::-webkit-slider-runnable-track {
    border-radius: 2px;
}

.rangeLeft::-moz-range-track {
    border-radius: 2px;
}

.rangeLeft::-ms-track {
    border-radius: 2px;
}

.rangeLeft::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: red;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: scaleX(-1) translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}

.rangeLeft::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: red;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: scaleX(-1) translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}

.rangeLeft::-ms-thumb{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: red;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: scaleX(-1) translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}

/*Right */

.rangeRight::-webkit-slider-runnable-track {
    border-radius: 2px;
}

.rangeRight::-moz-range-track {
    height: 5px;
    background-color: #13141B;
    border-radius: 2px;
}

.rangeRight::-ms-track {
    border-radius: 2px;
}


.rangeRight::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: darkslateblue;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}

.rangeRight::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: darkslateblue;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}

.rangeRight::-ms-thumb{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: darkslateblue;
    background-image: url('../../icons/range-vector.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(12px);

    border: none;
    width: 2px;
    height: 24px;
    cursor: pointer;

    pointer-events: all;
    z-index: 6;
}
