html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    line-height: 100%;
    font-weight: 400;
    margin: 0;
}

ul {
    list-style: none;
}

button,
input,
select,
textarea {
    margin: 0;
}

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    min-height: 100%;
    /*display: flex;*/
}


*, *::before, *::after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    cursor: pointer;
}

img,
video {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

html {
    background-color: rgba(8, 16, 40, 1);
    font-size: 1rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

a {
    cursor: pointer;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
    display: block;
}

body,
button,
input,
select,
textarea {
    font-family: 'Mona Sans', serif;
}

code,
pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: none;
    font-family: monospace;
}

/*Text*/

.f-100 {
    font-weight: 100;
}

.f-200 {
    font-weight: 200;
}

.f-300 {
    font-weight: 300;
}

.f-400 {
    font-weight: 400;
}

.f-500 {
    font-weight: 500;
}

.f-600 {
    font-weight: 600;
}

.f-700 {
    font-weight: 700;
}

.f-800 {
    font-weight: 800;
}

.f-900 {
    font-weight: 900;
}

.f-bold {
    font-weight: bold;
}

.f-bolder {
    font-weight: bolder;
}

.f-normal {
    font-weight: normal;
}

.f-lighter {
    font-weight: lighter;
}

.f-light {
    font-weight: lighter;
}

.f-italic {
    font-style: italic;
}

.f-underline {
    text-decoration: underline;
}

.f-line-through {
    text-decoration: line-through;
}

.f-uppercase {
    text-transform: uppercase;
}

.f-1rem {
    font-size: 1rem;
}

.f-medium {
    font-size: 1.2rem;
}

.f-large {
    font-size: 1.5rem;
}

.f-small {
    font-size: 0.8rem;
}

.lh-100 {
    line-height: 100%;
}

.lh-120 {
    line-height: 120%;
}

.lh-150 {
    line-height: 150%;
}

.lh-200 {
    line-height: 200%;
}

.letter-1 {
    letter-spacing: 1px;
}

.letter-2 {
    letter-spacing: 2px;
}

.letter-3 {
    letter-spacing: 3px;
}

.letter-4 {
    letter-spacing: 4px;
}

.letter-5 {
    letter-spacing: 5px;
}

.letter-6 {
    letter-spacing: 6px;
}

.letter-1-n {
    letter-spacing: -1px;
}

.letter-2-n {
    letter-spacing: -2px;
}

.letter-3-n {
    letter-spacing: -3px;
}

.letter-4-n {
    letter-spacing: -4px;
}

.letter-5-n {
    letter-spacing: -5px;
}

.letter-6-n {
    letter-spacing: -6px;
}


/*Titles*/
.h1, h1 {
    font-size: 3rem;
}

.h2, h2 {
    font-size: 2.5rem;
}

.h3, h3 {
    font-size: 2rem;
}

.h4, h4 {
    font-size: 1.5rem;
}

.h5, h5 {
    font-size: 1.25rem;
}

.h6, h6 {
    font-size: 1rem;
}

/*Paragraph* */

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

/*textbox*/
.textbox a:not(.button) {
    text-decoration: underline;
}

.textbox ul, .textbox ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
}


/*display*/

.flex {
    display: flex;
}

.block, .childs-block > * {
    display: block;
}

.inline-flex {
    display: inline-flex;
}

.grid {
    display: grid;
}

.hidden {
    display: none;
}

/*position*/
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.sticky {
    position: sticky;
}

.edges {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.left-auto {
    left: auto;
}

.right-auto {
    right: auto;
}

.top-auto {
    top: auto;
}

.bottom-auto {
    bottom: auto;
}

.top-1-n {
    top: -1rem;
}

.bottom-1-n {
    bottom: -1rem;
}

.left-1-n {
    left: -1rem;
}

.right-1-n {
    right: -1rem;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.top-quarter {
    top: 0.25rem
}

.bottom-quarter {
    bottom: 0.25rem
}

.left-quarter {
    left: 0.25rem
}

.right-quarter {
    right: 0.25rem
}


.top-half {
    top: 0.5rem
}

.bottom-half {
    bottom: 0.5rem
}

.left-half {
    left: 0.5rem
}

.right-half {
    right: 0.5rem
}


.top-1 {
    top: 1rem;
}

.bottom-1 {
    bottom: 1rem;
}

.left-1 {
    left: 1rem;
}

.right-1 {
    right: 1rem;
}

.top-2 {
    top: 2rem;
}

.bottom-2 {
    bottom: 2rem;
}

.left-2 {
    left: 2rem;
}

.right-2 {
    right: 2rem;
}

.top-3 {
    top: 3rem;
}

.bottom-3 {
    bottom: 3rem;
}

.left-3 {
    left: 3rem;
}

.right-3 {
    right: 3rem;
}

.top-4 {
    top: 4rem;
}

.bottom-4 {
    bottom: 4rem;
}

.left-4 {
    left: 4rem;
}

.right-5 {
    right: 5rem;
}

.top-5 {
    top: 5rem;
}

.bottom-5 {
    bottom: 5rem;
}

.left-5 {
    left: 5rem;
}

.right-5 {
    right: 5rem;
}

.top-6 {
    top: 6rem;
}

.bottom-6 {
    bottom: 6rem;
}

.left-6 {
    left: 6rem;
}

.right-6 {
    right: 6rem;
}

.top-8 {
    top: 8rem;
}

.bottom-8 {
    bottom: 8rem;
}

.left-8 {
    left: 8rem;
}

.right-8 {
    right: 8rem;
}

/*Translate*/
.tx-100px {
    transform: translateX(100px);
}

.tx-200px {
    transform: translateX(200px);
}

.tx-300px {
    transform: translateX(300px);
}

.tx-100px-n {
    transform: translateX(-100px);
}

.tx-200px-n {
    transform: translateX(-200px);
}

.tx-300px-n {
    transform: translateX(-200px);
}

/*Transition*/

.t-2 {
    transition: all 0.2s;
}

.t-3 {
    transition: all 0.3s;
}

.t-4 {
    transition: all 0.4s;
}

.t-5 {
    transition: all 0.5s;
}


/*Zindex*/
.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

.z-60 {
    z-index: 60;
}

.z-70 {
    z-index: 70;
}

.z-80 {
    z-index: 80;
}

.z-90 {
    z-index: 90;
}

.z-100 {
    z-index: 100;
}

.z-200 {
    z-index: 200;
}

.z-300 {
    z-index: 300;
}

.z-400 {
    z-index: 400;
}

.z-500 {
    z-index: 500;
}

.z-600 {
    z-index: 600;
}

.z-700 {
    z-index: 700;
}

.z-800 {
    z-index: 800;
}

.z-900 {
    z-index: 900;
}

.z-1000 {
    z-index: 1000;
}


/*layout flex*/

.align-center {
    align-items: center;
}

.align-end {
    align-items: end;
}

.align-start {
    align-items: start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.grows {
    flex-grow: 1;
}

.grows-0 {
    flex-grow: 0;
}

.shrinks {
    flex-shrink: 1;
}

.shrinks-0 {
    flex-shrink: 0;
}

.column {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.direction-column {
    flex-direction: column;
}

.direction-row-reverse {
    flex-direction: row-reverse;
}

.direction-column-reverse {
    flex-direction: column-reverse;
}

.no-wrap {
    flex-wrap: nowrap;
}

.wrap {
    flex-wrap: wrap;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}


/*columns*/

.grid2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid5 {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid6 {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid7 {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid8 {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
}

.col-span2 {
    grid-column-start: span 2;
}

.col-span3 {
    grid-column-start: span 3;
}

.col-span4 {
    grid-column-start: span 4;
}

.col-span5 {
    grid-column-start: span 5;
}

.col-span6 {
    grid-column-start: span 5;
}

.col-span7 {
    grid-column-start: span 5;
}

.col-span8 {
    grid-column-start: span 5;
}

.col-gap-quarter {
    grid-column-gap: 0.25rem;
}

.col-gap-half {
    grid-column-gap: 0.5rem;
}

.col-gap {
    grid-column-gap: 1rem;
}

.col-gap-2 {
    grid-column-gap: 2rem;
}

.col-gap-3 {
    grid-column-gap: 3rem;
}

.col-gap-4 {
    grid-column-gap: 4rem;
}

.row-gap-quarter {
    grid-row-gap: 0.25rem;
}

.row-gap-half {
    grid-row-gap: 0.5rem;
}

.row-gap {
    grid-row-gap: 1rem;
}

.row-gap-2 {
    grid-row-gap: 2rem;
}

.row-gap-3 {
    grid-row-gap: 3rem;
}

.row-gap-4 {
    grid-row-gap: 4rem;
}


/*Sizes*/

.mw-1800px {
    max-width: 1800px;
}

.mw-1600px {
    max-width: 1600px;
}

.mw-1360px {
    max-width: 1360px;
}

.mw-1200px {
    max-width: 1200px;
}

.mw-1056px {
    max-width: 1056px;
}

.mw-600px {
    max-width: 600px;
}

.mw-400px {
    max-width: 400px;
}

.mw-300px {
    max-width: 300px;
}

.mw-200px {
    max-width: 200px;
}

.mw-100px {
    max-width: 100px;
}

.mw-100 {
    max-width: 100%;
}

.mw-90 {
    max-width: 90%;
}

.mw-80 {
    max-width: 80%;
}

.mw-70 {
    max-width: 70%;
}

.mw-60 {
    max-width: 60%;
}

.mw-50 {
    max-width: 50%;
}

/*Width*/

.w-80vw {
    width: 70vw;
}

.w-100vw {
    width: 100vw;
}

.w-1rem {
    width: 1rem;
}

.w-2rem {
    width: 2rem;
}

.w-3rem {
    width: 3rem;
}

.w-4rem {
    width: 4rem;
}

.w-5rem {
    width: 5rem;
}

.w-6rem {
    width: 6rem;
}

.w-7rem {
    width: 7rem;
}

.w-8rem {
    width: 8rem;
}

.w-10rem {
    width: 10rem;
}

.w-15rem {
    width: 15rem;
}

.w-20rem {
    width: 20rem;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}


.w-60 {
    width: 60%;
}


.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}


.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}


.w-10 {
    width: 10%;
}

.w-auto {
    width: auto;
}

.w-100px {
    width: 100px;
}

.w-100px {
    width: 100px;
}

.w-10px {
    width: 10px;
}

.w-20px {
    width: 20px;
}

.w-30px {
    width: 30px;
}

.w-40px {
    width: 40px;
}

.w-50px {
    width: 50px;
}

.w-60px {
    width: 60px;
}

.w-70px {
    width: 70px;
}

.w-80px {
    width: 80px;
}

.w-90px {
    width: 90px;
}

.w-100px {
    width: 100px;
}

.w-200px {
    width: 200px;
}

.w-300px {
    width: 300px;
}

.w-400px {
    width: 400px;
}

.w-500px {
    width: 500px;
}

.w-600px {
    width: 600px;
}


/*height*/

.h-auto {
    height: auto;
}

.h-halfrem {
    height: 0.5rem;
}

.h-1rem {
    height: 1rem;
}

.h-2rem {
    height: 2rem;
}

.h-3rem {
    height: 3rem;
}

.h-4rem {
    height: 4rem;
}

.h-5rem {
    height: 5rem;
}

.h-6rem {
    height: 6rem;
}

.h-7rem {
    height: 7rem;
}

.h-8rem {
    height: 8rem;
}

.h-10rem {
    height: 10rem;
}

.h-15rem {
    height: 15rem;
}

.h-20rem {
    height: 20rem;
}

.h-10vh {
    height: 10vh;
}

.h-20vh {
    height: 20vh;
}

.h-30vh {
    height: 30vh;
}

.h-40vh {
    height: 40vh;
}

.h-50vh {
    height: 50vh;
}

.h-60vh {
    height: 60vh;
}

.h-100vw {
    height: 100vw;
}

.h-100vh {
    height: 100vh;
}

.mh-100vh {
    min-height: 100vh;
}

.mh-90vh {
    min-height: 90vh;
}

.mh-80vh {
    min-height: 80vh;
}

.mh-70vh {
    min-height: 70vh;
}

.mh-60vh {
    min-height: 60vh;
}

.mh-50vh {
    min-height: 50vh;
}

.mh-40vh {
    min-height: 40vh;
}

.mh-30vh {
    min-height: 30vh;
}

.mh-20vh {
    min-height: 20vh;
}

.mh-10vh {
    min-height: 10vh;
}

.h-10 {
    height: 10%;
}

.h-20 {
    height: 20%;
}

.h-30 {
    height: 30%;
}

.h-40 {
    height: 40%;
}

.h-50 {
    height: 50%;
}

.h-60 {
    height: 60%;
}

.h-100 {
    height: 100%;
}

/*Squares*/
.icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.sq-1rem {
    width: 1rem;
    height: 1rem;
}

.sq-1halfrem {
    width: 1rem;
    height: 1rem;
}

.sq-2rem {
    width: 2rem;
    height: 2rem;
}

.sq-3rem {
    width: 3rem;
    height: 3rem;
}

.sq-4rem {
    width: 4rem;
    height: 4rem;
}

.sq-5rem {
    width: 5rem;
    height: 5rem;
}

.sq-6rem {
    width: 6rem;
    height: 6rem;
}

.sq-7rem {
    width: 7rem;
    height: 7rem;
}

.sq-8rem {
    width: 8rem;
    height: 8rem;
}

.sq-10rem {
    width: 10rem;
    height: 10rem;
}

.sq-15rem {
    width: 15rem;
    height: 15rem;
}

.sq-20rem {
    width: 20rem;
    height: 20rem;
}

/*Overflows*/

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-auto {
    overflow-x: auto;
}

.overflow-hidden {
    overflow: hidden;
}

/*Margins*/
.m-0 {
    margin: 0;
}

.m-auto {
    margin: auto;
}

.mver-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mhor-auto {
    margin-right: auto;
    margin-left: auto;
}

.m {
    margin: 1rem;
}

.ml {
    margin-left: 1rem;
}

.mr {
    margin-right: 1rem;
}

.mb {
    margin-bottom: 1rem;
}

.mt {
    margin-top: 1rem;
}

.mv {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.mhor {
    margin-right: 1rem;
    margin-left: 1rem;
}

.m-half {
    margin: 0.5rem;
}

.ml-half {
    margin-left: 0.5rem;
}

.mr-half {
    margin-right: 0.5rem;
}

.mb-half {
    margin-bottom: 0.5rem;
}

.mt-half {
    margin-top: 0.5rem;
}

.mv-half {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.mhor-half {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}


.m-quarter {
    margin: 0.25rem;
}

.ml-quarter {
    margin-left: 0.25rem;
}

.mr-quarter {
    margin-right: 0.25rem;
}

.mb-quarter {
    margin-bottom: 0.25rem;
}

.mt-quarter {
    margin-top: 0.25rem;
}

.mver-quarter {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.mhor-quarter {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}


.m-2 {
    margin: 2rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mver-2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.mhor-2 {
    margin-right: 2rem;
    margin-left: 2rem;
}

.m-3 {
    margin: 3rem;
}

.ml-3 {
    margin-left: 3rem;
}

.mr-3 {
    margin-right: 3rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mver-3 {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.mhor-3 {
    margin-right: 3rem;
    margin-left: 3rem;
}

.m-4 {
    margin: 4rem;
}

.ml-4 {
    margin-left: 4rem;
}

.mr-4 {
    margin-right: 4rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mver-4 {
    margin-bottom: 4rem;
    margin-top: 4rem;
}

.mhor-4 {
    margin-right: 4rem;
    margin-left: 4rem;
}

/*Padding*/
.p {
    padding: 1rem;
}

.pl {
    padding-left: 1rem;
}

.pr {
    padding-right: 1rem;
}

.pb {
    padding-bottom: 1rem;
}

.pt {
    padding-top: 1rem;
}

.pver, .childs-pver > * {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.phor {
    padding-right: 1rem;
    padding-left: 1rem;
}

.p-half {
    padding: 0.5rem;
}

.pl-half {
    padding-left: 0.5rem;
}

.pr-half {
    padding-right: 0.5rem;
}

.pb-half {
    padding-bottom: 0.5rem;
}

.pt-half {
    padding-top: 0.5rem;
}

.pver-half, .childs-pver-half > * {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.phor-half {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}


.p-quarter {
    padding: 0.25rem;
}

.pl-quarter {
    padding-left: 0.25rem;
}

.pr-quarter {
    padding-right: 0.25rem;
}

.pb-quarter {
    padding-bottom: 0.25rem;
}

.pt-quarter {
    padding-top: 0.25rem;
}

.pver-quarter, .childs-pver-quarter > * {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}

.phor-quarter {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
}


.p-2 {
    padding: 2rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pver-2 {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.phor-2 {
    padding-right: 2rem;
    padding-left: 2rem;
}

.p-3 {
    padding: 3rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.pt-3 {
    padding-top: 3rem;
}

.pver-3 {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.phor-3 {
    padding-right: 3rem;
    padding-left: 3rem;
}

.p-4 {
    padding: 4rem;
}

.pl-4 {
    padding-left: 4rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pb-4 {
    padding-bottom: 4rem;
}

.pt-4 {
    padding-top: 4rem;
}

.pver-4 {
    padding-bottom: 4rem;
    padding-top: 4rem;
}

.phor-4 {
    padding-right: 4rem;
    padding-left: 4rem;
}

.p-6 {
    padding: 6rem;
}

.pl-6 {
    padding-left: 6rem;
}

.pr-6 {
    padding-right: 6rem;
}

.pb-6 {
    padding-bottom: 6rem;
}

.pt-6 {
    padding-top: 6rem;
}

.pver-6 {
    padding-bottom: 6rem;
    padding-top: 6rem;
}

.phor-6 {
    padding-right: 6rem;
    padding-left: 6rem;
}

.p-8 {
    padding: 8rem;
}

.pl-8 {
    padding-left: 8rem;
}

.pr-8 {
    padding-right: 8rem;
}

.pb-8 {
    padding-bottom: 8rem;
}

.pt-8 {
    padding-top: 8rem;
}

.pver-8 {
    padding-bottom: 8rem;
    padding-top: 8rem;
}

.phor-8 {
    padding-right: 8rem;
    padding-left: 8rem;
}


/*objects*/
.obj-cover {
    object-fit: cover;
}

.obj-contain {
    object-fit: contain;
}

.obj-top {
    object-position: top;
}

.obj-bottom {
    object-position: bottom;
}

.obj-left {
    object-position: left;
}

.obj-right {
    object-position: right;
}

.bg-contain {
    background-size: contain;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat {
    background-repeat: repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-top {
    background-position: top;
}

.bg-bottom {
    background-position: bottom;
}

.bg-left {
    background-position: left;
}

.bg-right {
    background-position: right;
}

.bg-center {
    background-position: center;
}

/*Shadows*/
.sh-4-black, .hover\:sh-4-black:hover, .focus\:sh-4-black:focus {
    box-shadow: 0 0 4px #000;
}

.sh-4-black50, .hover\:sh-4-black50:hover, .focus\:sh-4-black50:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.sh-4-black20, .hover\:sh-4-black20:hover, .focus\:sh-4-black20:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.sh-4-black10, .hover\:sh-4-black10:hover, .focus\:sh-4-black10:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.sh-8-black, .hover\:sh-8-black:hover, .focus\:sh-8-black:focus {
    box-shadow: 0 0 8px #000;
}

.sh-8-black50, .hover\:sh-8-black50:hover, .focus\:sh-8-black50:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.sh-8-black20, .hover\:sh-8-black20:hover, .focus\:sh-8-black20:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.sh-8-black10, .hover\:sh-8-black10:hover, .focus\:sh-8-black10:focus {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}


/*Corners*/
.br-0 {
    border-radius: 0;
}

.br-quarter {
    border-radius: 0.25rem;
}

.br-half {
    border-radius: 0.5rem;
}

.br-1half {
    border-radius: 1.5rem;
}

.br {
    border-radius: 1rem;
}

.br-2 {
    border-radius: 2rem;
}

.br-2half {
    border-radius: 2.5rem;
}

.br-3 {
    border-radius: 3rem;
}

.br-4 {
    border-radius: 4rem;
}

.br-8 {
    border-radius: 8rem;
}

.br-50 {
    border-radius: 50%;
}

/*borders*/
.b-0 {
    border: 0;
}

.b-top-0 {
    border-top: 0 solid;
}

.b-right-0 {
    border-right: 0 solid;
}

.b-bottom-0 {
    border-bottom: 0 solid;
}

.b-left-0 {
    border-left: 0 solid;
}

.b {
    border: 1px solid;
}

.b-right {
    border-right: 1px solid;
}

.b-bottom {
    border-bottom: 1px solid;
}

.b-left {
    border-left: 1px solid;
}

.b-top {
    border-top: 1px solid;
}

.b-2 {
    border: 2px solid;
}

.b-right-2 {
    border-right: 2px solid;
}

.b-bottom-2 {
    border-bottom: 2px solid;
}

.b-left-2 {
    border-left: 2px solid;
}

.b-top-2 {
    border-top: 2px solid;
}


.b-3 {
    border: 3px solid;
}

.b-right-3 {
    border-right: 3px solid;
}

.b-bottom-3 {
    border-bottom: 3px solid;
}

.b-left-3 {
    border-left: 3px solid;
}

.b-top-3 {
    border-top: 3px solid;
}

.b-4 {
    border: 4px solid;
}

.b-right-4 {
    border-right: 4px solid;
}

.b-bottom-3 {
    border-bottom: 4px solid;
}

.b-left-4 {
    border-left: 4px solid;
}

.b-top-4 {
    border-top: 4px solid;
}

/*Border-Colors*/

.b-black, .hover\:b-black:hover, .focus\:b-black:focus {
    border-color: #000;
}

.b-white, .hover\:b-white:hover, .focus\:b-white:focus {
    border-color: #fff;
}

.b-grey1000, .hover\:b-grey1000:hover, .focus\:b-grey1000:focus {
    border-color: #111013;
}

.b-grey900, .hover\:b-grey900:hover, .focus\:b-grey900:focus {
    border-color: #2E2C32;
}

.b-grey800, .hover\:b-grey800:hover, .focus\:b-grey800:focus {
    border-color: #4B4752;
}

.b-grey700, .hover\:b-grey700:hover, .focus\:b-grey700:focus {
    border-color: #625D6B;
}

.b-grey600, .hover\:b-grey600:hover, .focus\:b-grey600:focus {
    border-color: #797185;
}

.b-grey500, .hover\:b-grey500:hover, .focus\:b-grey500:focus {
    border-color: #958DA1;
}

.b-grey400, .hover\:b-grey400:hover, .focus\:b-grey400:focus {
    border-color: #B1ABBC;
}

.b-grey300, .hover\:b-grey300:hover, .focus\:b-grey300:focus {
    border-color: #CCC7D3;
}

.b-grey200, .hover\:b-grey200:hover, .focus\:b-grey200:focus {
    border-color: #E3DFEA;

}

.b-grey100, .hover\:b-grey100:hover, .focus\:b-grey100:focus {
    border-color: #F5F1FA;
}

.b-grey10, .hover\:b-grey100:hover, .focus\:b-grey10:focus {
    border-color: #FDFCFF;
}

.focus\:outline-0 {
    outline: 0;
}


/*Colors*/
.black, .hover\:black:hover {
    background-color: #000;
}

.white, .hover\:white:hover {
    background-color: #fff;
}

.grey1000, .hover\:grey1000:hover {
    background-color: #111013;
}

.grey900, .hover\:grey900:hover {
    background-color: #2E2C32;
}

.grey800, .hover\:grey800:hover {
    background-color: #4B4752;
}

.grey700, .hover\:grey700:hover {
    background-color: #625D6B;
}

.grey600, .hover\:grey600:hover {
    background-color: #797185;
}

.grey500, .hover\:grey500:hover {
    background-color: #958DA1;
}

.grey400, .hover\:grey400:hover {
    background-color: #B1ABBC;
}

.grey300, .hover\:grey300:hover {
    background-color: #CCC7D3;
}

.grey200, .hover\:grey200:hover {
    background-color: #E3DFEA;

}

.grey100, .hover\:grey100:hover {
    background-color: #F5F1FA;
}

.grey10, .hover\:grey100:hover {
    background-color: #FDFCFF;
}


/*Font Colors*/

.t-black, .hover\:t-black:hover {
    color: #000;
}

.t-white, .hover\:t-white:hover {
    color: #fff;
}

.t-grey1000, .hover\:t-grey1000:hover {
    color: #111013;
}

.t-grey900, .hover\:t-grey900:hover {
    color: #2E2C32;
}

.t-grey800, .hover\:t-grey800:hover {
    color: #4B4752;
}

.t-grey700, .hover\:t-grey700:hover {
    color: #625D6B;
}

.t-grey600, .hover\:t-grey600:hover {
    color: #797185;
}

.t-grey500, .hover\:t-grey500:hover {
    color: #958DA1;
}

.t-grey400, .hover\:t-grey400:hover {
    color: #B1ABBC;
}

.t-grey300, .hover\:t-grey300:hover {
    color: #CCC7D3;
}

.t-grey200, .hover\:t-grey200:hover {
    color: #E3DFEA;

}

.t-grey100, .hover\:t-grey100:hover {
    color: #F5F1FA;
}

.t-grey10, .hover\:t-grey100:hover {
    color: #FDFCFF;
}


/*Opacity*/

.op-100, .hover\:op-100:hover {
    opacity: 1;
}

.op-75, .hover\:op-75:hover {
    opacity: .75;
}

.op-50, .hover\:op-50:hover {
    opacity: .5;
}

.op-25, .hover\:op-25:hover {
    opacity: .25;
}

.op-0, .hover\:op-0:hover {
    opacity: 0;
}

/*Tables*/
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}


/*forms*/
.button {
    display: inline-flex;
    padding: 1rem 2rem;
    height: 2.25rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 100%;
    text-align: center;
    appearance: none;
}

.button-large {
    display: inline-flex;
    padding: 1rem 2rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 100%;
    text-align: center;
    appearance: none;
}

.button-small {
    display: inline-flex;
    padding: 1rem 1rem;
    height: 2rem;
    align-items: center;
    font-size: 1rem;
    justify-content: center;
    line-height: 100%;
    text-align: center;
    appearance: none;
}

.label {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
}

.input {
    width: 100%;
    height: 3rem;
    padding: 1rem;
    font-size: 1.1rem;
    border: 1px solid #1D3745;
    appearance: none;
    background: none;
    outline: none;
}

select.input {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: 100% 50%;
}


.input-small {
    width: 100%;
    height: 2rem;
    padding: 0.2rem;
    font-size: 0.8rem;
}

.textarea {
    width: 100%;
    height: 10rem;
    padding: 1rem;
    font-size: 1.2rem;
    border: 1px solid #1D3745;
}


.checkbox {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #1D3745;
}

.checkbox:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-size: 80%;
}

.radio {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    vertical-align: middle;
    appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #1D3745;
    border-radius: 50%;
}

.radio:checked {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
    background-size: 70%;
}


@media (max-width: 768px) {


}