/*
.icon-menu{
    background-image: url("./images/icon-menu.svg");
}

.icon-x{
    background-image: url("./images/icon-x.svg");
}

.icon-play{
    background-image: url("./images/icon-play.svg");
}*/

.calc-h-100-4 {
    height: calc(100vh - 4rem);
}

.sh-16-black10, .hover\:sh-16-black10:hover, .focus\:sh-16-black10:focus {
    box-shadow: 5px 3px 22px rgba(0, 0, 0, 0.05);
}

header {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.5);
}