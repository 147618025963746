.progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    background: rgba(0, 194, 240, 0.6);
}

.progress::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 194, 240, 0.6);
}

.progress::-moz-range-track {
    height: 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 194, 240, 0.6);
}

.progress::-ms-track {
    height: 30px;
    background: transparent;
    border-radius: 2px;
    border: none;
    color: transparent;
    /* Quita el relleno de IE */
    background-color: transparent;
}

.progress::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    width: 10px;
    height: 10px;
    background-color: white; /* Puedes cambiar el color del thumb */
    cursor: pointer;
    border-radius: 50%;
}

.progress::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
    border: none; /* Firefox necesita un borde explícito */
}


.progress::-ms-thumb {
    width: 10px;
    height: 10px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
    border: none;
}
