@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.main-color-background{
    background-color: rgba(8, 16, 40, 1);
}

.left-main-menu-border{
    border-right: 1px solid rgba(11, 23, 57, 1);
}

.left-main-menu {
    border-right: 100px solid rgba(11, 23, 57, 1);
    background-color: rgba(8, 16, 40, 1);
}

.nav-link.active-nav-item-font-color {
    color: rgba(203, 60, 255, 1);
}

.active-nav-item-font-color {
    color: rgba(203, 60, 255, 1) !important;
}

.theme-t-dark-active{
    font-family: 'Mona-Sans', serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: rgba(174, 185, 225, 1);
}

.left-main-menu-separator{
    width: 100%;
    height: 1px;
    border: 1px solid rgba(174, 185, 225, 1);
}

.left-main-menu-separator-dark{
    width: 100%;
    height: 1px;
    border: 1px solid rgba(10, 19, 48, 1)
}

/* Grid Layout Gap*/

/* Rows */

.grid-row5{
    display: grid;
    gap: 5px;
    grid-auto-flow: row;
}

.grid-row10{
    display: grid;
    gap: 10px;
    grid-auto-flow: row;
}

.grid-row20{
    display: grid;
    gap: 20px;
    grid-auto-flow: row;
}

.grid-row038{
    display: grid;
    gap: 0.03875rem;
    grid-auto-flow: row;
}

/* Columns */

.grid-auto-col10{
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
}

.grid-auto-to-mobile{
    @media (max-width: 700px){
        grid-auto-flow: row;
    }
}

.grid-auto-col20{
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
}

.grid-auto-col35{
    display: grid;
    grid-auto-flow: column;
    gap: 35px;
}

.grid-template-col325{
    display: grid;
    grid-template-columns: 1fr 325px;
    gap: 48px;
}

.grid-template-col5-1{
    display: grid;
    grid-template-columns: .5fr 1fr;
    gap: 20px;
}
