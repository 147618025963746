table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ddd;
}

th, td {
    text-align: center;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

tbody tr:hover {
    background-color: #f5f5f5;
}


th, td {
    border: 1px solid #ddd;
}

@media (max-width: 968px) {
    th, td {
        display: block;
        width: 100%;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
    }
}